import { render, staticRenderFns } from "./modifyPhone.vue?vue&type=template&id=aab97d4e&scoped=true&"
import script from "./modifyPhone.vue?vue&type=script&lang=js&"
export * from "./modifyPhone.vue?vue&type=script&lang=js&"
import style0 from "./modifyPhone.vue?vue&type=style&index=0&id=aab97d4e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aab97d4e",
  null
  
)

export default component.exports