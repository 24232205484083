<template>
    <div class="home">
        <div class="white-bg">
            <div class="baccount-tit">
                <h3 class="g-title">{{ title }}</h3>
            </div>
            <div class="zpanle modifyp-panle">
                <div class="account-hd">
                    <div class="account-cnt">
                        <img
                            class="top-icon-img"
                            :src="$img.phone"
                        />
                        <p class="accont-slogin">{{ title }}</p>
                        <p style="margin-top:20px;color:#6578dc;font-size:16px;">请注意，询龙网的登陆账号即是手机号码，若您更改了绑定的手机号，则登陆时使用的账号也会相应改变！</p>
                    </div>
                </div>
                
                <div class="panner-bd modifypd">
                    <el-form
                        ref="accountForm"
                        :rules="formRules"
                        :model="accountForm"
                        class="g-form"
                        style="width:370px;"
                    >
                        <el-form-item prop="new_mobile">
                            <el-input
                                type="tel"
                                v-model="accountForm.new_mobile"
                                placeholder="请输入您的新手机号"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            class="yzm-item"
                            prop="code"
                        >
                            <el-input
                                type="text"
                                v-model="accountForm.code"
                                placeholder="请输入验证码"
                                auto-complete="off"
                            ></el-input>
                            <el-button
                                class="dx-code"
                                @click="getMobileCode"
                            >{{time==0?'获取验证码':time+'秒后获取'}}</el-button>
                        </el-form-item>
                        <el-form-item class="btn-wrap ml-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="handleSubmit()"
                                >确认修改</el-button>
                            </div>
                        </el-form-item>
                        <el-form-item class="btn-wrap back-wrap mr-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="$router.back(-1)"
                                >返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "validEmail",
    metaInfo() {
        return {
            title: "修改绑定手机号码 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        let _this = this;
        return {
            handleClickkefu: false,
            title: '', // 显示标题
            accountForm: {
                new_mobile: "",
                mobile: "",
                code: "",
                type: 2,
                action: 2
            },
            time: 0,
            timer: null,
            formRules: {
                new_mobile: [
                    {
                        required: true,
                        message: "请输入新的联系电话",
                        trigger: "blur"
                    },
                    {
                        validator: _this.validata.mobile(),
                        trigger: ["change", "blur"]
                    }
                ],
                code: [
                    {
                        required: true,
                        message: "请输入短信验证码",
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo", "loginOut"]),//刷新用户数据方法
        getMobileCode() {
            let _this = this;
            // 获取验证码，待调整
            _this.$refs["accountForm"].validateField(
                "new_mobile",
                (val, error) => {
                    if (!val && _this.time == 0) {
                        _this.post(
                            "/user/index/get_sms_code",
                            {
                                mobile: _this.accountForm.new_mobile,
                                template: 'bind_mobile'
                            },
                            res => {
                                if (200 == res.code) {
                                    _this.$message.success(res.msg);
                                    _this.time = 60;
                                    _this.startTimer();
                                } else {
                                    _this.$message.error(res.msg);
                                    return;
                                }
                            }
                        );
                    }
                }
            );
        },
        startTimer() {
            // 倒计时
            let _this = this;
            if (!_this.timer) {
                _this.timer = setInterval(() => {
                    if (_this.time > 0) {
                        _this.time--;
                        sessionStorage.setItem("bindMphoneTime", _this.time);
                    } else {
                        clearInterval(_this.timer);
                        _this.timer = null;
                        sessionStorage.removeItem("bindMphoneTime");
                    }
                }, 1000);
            }
        },
        handleSubmit() {
            // 提交修改手机号码请求
            this.$refs["accountForm"].validate((valid, validInfoObj) => {
                if (valid) {
                    this.post(
                        "/user/user/bind_mobile",
                        {
                            mobile: this.accountForm.new_mobile,
                            code: this.accountForm.code
                        },
                        data => {
                            if (data.code != 200) {
                                this.$message.error(data.msg);
                                return;
                            }
                            this.$message.success('修改成功，请重新登录');
                            this.ajaxUserInfo();
                            //退出登录
                            this.loginOut();
                            this.$router.push('/login/index?type=login');
                        }
                    );
                }
            });
        },
    },
    created() {
        let _time = sessionStorage.getItem("bindMphoneTime");
        this.title = this.$route.query.title;
        if (_time) {
            this.time = _time;
            this.startTimer();
        }
    },
    computed: {
        ...mapState(["userInfo"])
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
</style>


